#top{
	.header-logo{
		margin-right: auto !important;
	}
	.hamburger-content-inner{
		width: 100%;
	}
	.hero-section{
		.container{
			padding-top: 220px !important;
		}
	}
	.home-hero-section{
		.av_textblock_section{
			.avia_textblock{
				padding-right: 75px;
			}
		}
	}
	.footer-section{
		.footer--info-column{
			padding-left: 75px;
		}
		.footer--nav{
			width: 70%;
			margin-left: auto;
		}
	}
	.ep-content-grid{
		.entry-content{
			.entry-background{
				top: 0;
			}
		}
	}
	.resources-hero-section{
		.entry-content-wrapper{
			width: 100%;
		}
	}
	.ep-posts-slider{
		.next{
			left: 0;
			transform: translateX(250%);
		}
	}
	.our-approach--map{
		.lottie-animation-inner{
			img{
				height: 70% !important;
			}
		}
	}
	.home--publication-text{
		padding-right: 0 !important;
	}
	.home--publications-post:last-child{
		.entry-title, .entry-excerpt{
			width: 80%;
		}
		.entry-excerpt{
			padding-bottom: 160px;
		}
		.avia-button-wrap{
			height: 156% !important;
			.avia-button{
				min-width: 80px;
				span{
					min-width: 80px !important;
					height: 42px;
				}
			}
		}
	}
	.posts-grid{
		.entry-content-inner{
			height: 250px !important;
		}
		.entry-image-content{
			height: 240px;
		}
	}
	.mission--our-vision{
		.av-section-color-overlay{
			height: 200% !important;
		}
	}
	.hamburger-overlay{
		background-image: url("/wp-content/uploads/HRFK-Home-Burger-Menu-scaled.jpg") !important;
		background-color: none;
		background-blend-mode: none;
	}
	.mission--our-values-lists{
		.flickity-button{
			height: 56px !important;
			width: 56px !important;
			border-radius: 0 !important;
			&.previous{
				transform: translateY(-84px) translateX(50%) scaleX(-1) !important;
			}		
		}
	}
}